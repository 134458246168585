// import { useState } from "react";
// import { useSelector } from "react-redux";
// import props from "../../../../redux/props";
import {
  StyledReason,
  HeadlineWrapper,
  Number,
  Headline,
  Text,
} from "./Reason.Styled";

const Reason = ({ data, i }) => {
  // const translation = useSelector((s) => s[props.TRANSLATION]);
  // const [open, setOpen] = useState(true);
  const { headline, text } = data;

  return (
    <StyledReason>
      <HeadlineWrapper>
        <Number>{i >= 9 ? i + 1 : `0${i + 1}`}</Number>
        <Headline>{headline}</Headline>
      </HeadlineWrapper>
      <Text show={true}>{text}</Text>
      {/* <Text show={open}>{text}</Text> */}
      {/* <TextToggle onClick={() => setOpen(!open)}>
        {translation.career.reasons.pointTextToggle[open ? "close" : "open"]}
      </TextToggle> */}
    </StyledReason>
  );
};

export default Reason;
