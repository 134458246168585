import {
  StyledHeader,
  Toggle,
  MobileNavigation,
  CustomLink,
  Wrapper,
  Pages,
  LogoWrapper,
} from "./Header.Styled";
import burger from "../../../assets/image/burger.png";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import props from "../../../redux/props";
import { setLocal } from "../../../redux/action/local";
import { getUUID } from "../../../utility";
import Logo from "../../SubComponents/Logo/Logo";

const Header = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  const token = useSelector((s) => s.userToken);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const logout = () => {
    const cookies = new Cookies();
    cookies.remove("token", { path: "/" });
    dispatch(setLocal(props.ACCOUNT, null));
    dispatch(setLocal(props.TOKEN, null));
    navigate("/");
  };

  const goHome = () => {
    if (token) {
      navigate("/dashboard/home");
    } else {
      navigate("/");
    }
  };

  const isActive = (link) => window.location.pathname === link;

  return (
    <StyledHeader>
      <Wrapper>
        <LogoWrapper>
          <Logo onClick={goHome} height="40px" dark />
        </LogoWrapper>
        <Pages>
          {translation.header.pages
            .filter((p) => !p.mobileOnly)
            .map((p) => (
              <CustomLink
                key={getUUID()}
                href={p.href}
                text={p.name}
                newTab={p.newTab}
                className={isActive(p.href) ? "active" : ""}
              />
            ))}
        </Pages>
        <Toggle src={burger} alt="Menu" onClick={() => setOpen(!open)} />
      </Wrapper>
      {open && (
        <MobileNavigation>
          {translation.header.pages.map((p) => (
            <CustomLink
              key={getUUID()}
              newTab={p.newTab}
              href={p.href}
              text={p.name}
              className={isActive(p.href) ? "active" : ""}
            />
          ))}
          {token && (
            <CustomLink
              onClick={logout}
              text={translation.dashboard.logoutCTA}
            />
          )}
        </MobileNavigation>
      )}
    </StyledHeader>
  );
};

export default Header;
