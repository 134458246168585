import styled from "styled-components";

export const StyledOpenPositions = styled.div`
  padding: 50px 0px 100px 0px;
`;

export const Headline = styled.div`
  font-size: ${({ theme }) => theme.font.size.xlg};
  color: ${({ theme }) => theme.color.font.primary};
  text-align: center;
  font-family: bold;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.lg};
  }
`;
