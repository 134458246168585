import styled from "styled-components";

export const StyledHero = styled.div`
  position: relative;
`;
export const HeroImage = styled.img`
  height: 60vh;
  width: 100%;
  object-fit: cover;
  object-position: center ${({ top }) => (top ? "25%" : "15%")};
  margin-top: ${({ theme }) => theme.header.height};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 40vh;
  }
`;

export const HeroContentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  position: absolute;
  bottom: 0px;
  left: 0px;
  ${(props) =>
    props.center &&
    `
    height: 100%;
    top: 0px;
  `}
`;
export const HeroContentWrapper = styled.div`
  padding: 3% 5%;
  text-align: left;
`;
export const Title = styled.div`
  font-size: 70px;
  font-family: "bold";
  color: ${({ theme }) => theme.color.font.secondary};
  line-height: 60px;
  margin-top: 10px;
  text-shadow: 1px 1px 2px rgba(1, 160, 228, 1);
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: 30px;
    line-height: 25px;
  }
`;
export const By = styled.div`
  font-size: 30px;
  color: ${({ theme }) => theme.color.font.secondary};
  margin-top: 10px;
  margin-bottom: 25px;
  line-height: 30px;
  text-shadow: 1px 1px 2px rgba(1, 160, 228, 1);
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: 20px;
    line-height: 20px;
    margin-top: 15px;
  }
`;
