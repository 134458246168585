import Layout from "../Layout/Layout";
import { StyledAboutUs } from "./AboutUs.Styled";
import heroImage from "../../assets/image/about-us/hero.jpg";
import { useSelector } from "react-redux";
import props from "../../redux/props";
import OpenPositions from "../Career/OpenPositions/OpenPositions";
import Location from "./Location/Location";
import Text from "./Text/Text";
import Images from "./Images/Images";
import Hero from "../SubComponents/Hero/Hero";

const AboutUs = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);

  return (
    <Layout authRequired={false}>
      <StyledAboutUs>
        <Hero
          title={translation.aboutUs.title}
          by={translation.aboutUs.by}
          bg={heroImage}
        />
        <Text />
        <Images />
        <Location />
        <OpenPositions />
      </StyledAboutUs>
    </Layout>
  );
};

export default AboutUs;
