import fetch, { METHOD } from '../fetch'

const getFileArray = (files) => {
  if (Array.isArray(files) && files.length > 0) return files
  if (!Array.isArray(files) && !!files && typeof files === 'object' && files[0]) return [...files]
  if (files) return [files]
  return false
}

const call = async (token, route, body = {}, files = null, progressCallback = null, fileDownloadMimeType = null) => {
  const backendRoute = `/functional-interface/${route}`

  const fileArray = getFileArray(files)

  const result = await fetch(METHOD.POST, backendRoute, { ...body, token }, fileArray, progressCallback, fileDownloadMimeType)
  if (result instanceof Blob) {
    return result
  } else if (result.status && result.response) {
    return result
  }
  return false
}

export default call
