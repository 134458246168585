import { useSelector } from "react-redux";
import { StyledText, Item } from "./Text.Styled";
import { Container } from "@think-internet/ui-components";
import props from "../../../redux/props";
import { getUUID } from "../../../utility";

const Text = () => {
  const t = useSelector((s) => s[props.TRANSLATION]);

  return (
    <Container>
      <StyledText>
        {t.aboutUs.text.map((t) => (
          <Item key={getUUID()}>{t}</Item>
        ))}
      </StyledText>
    </Container>
  );
};

export default Text;
