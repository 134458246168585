import styled from "styled-components";
import { Link } from "@think-internet/ui-components";

export const StyledFooter = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.color.background.footer};
  padding: 50px 0px;
`;

export const Column = styled.div`
  flex: 1 1 0px;
`;

export const CustomLink = styled(Link)`
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.font.size.sm};
  ${({ margin }) => margin && "margin-top: 15px;"};
  ${({ block }) => block && "display: block;"};
`;

export const CustomText = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  color: ${({ theme }) => theme.color.font.secondary};
  ${({ margin }) => margin && "margin-top: 15px;"};
  ${({ bold }) => bold && "font-family: 'bold';"};
`;

export const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  align-items: flex-start;
`;

export const Socials = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 5px 0px;
`;
export const Social = styled.a``;
export const SocialIcon = styled.img`
  max-height: 30px;
  max-width: 30px;
`;
