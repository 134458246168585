import styled from "styled-components";

export const StyleSeparator = styled.a`
  display: block;
  margin-top: 50px;
`;

export const Image = styled.img`
  width: 100%;
`;
