import { StyledImages, Image } from "./Images.Styled";
import { Container } from "@think-internet/ui-components";
import { getUUID } from "../../../utility";
import img1 from "../../../assets/image/about-us/images/1.jpg";
import img2 from "../../../assets/image/about-us/images/2.jpg";
import img3 from "../../../assets/image/about-us/images/3.jpg";
import img4 from "../../../assets/image/about-us/images/4.jpg";
import img5 from "../../../assets/image/about-us/images/5.jpg";

const Images = () => {
  const images = [img1, img2, img3, img4, img5];

  return (
    <Container>
      <StyledImages>
        {images.map((img) => (
          <Image key={getUUID()} src={img} />
        ))}
      </StyledImages>
    </Container>
  );
};

export default Images;
