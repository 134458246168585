import styled from "styled-components";

export const StyledBanner = styled.div`
  background-image: url(${({ bg }) => bg});
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  flex-wrap: wrap;
  background-size: cover;
  background-position: center center;
  margin-top: 100px;
`;

export const Glass = styled.div`
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextWrapper = styled.div``;

export const Text = styled.div`
  text-align: center;
  width: 100%;
  font-size: ${({ theme }) => theme.font.size.lg};
  line-height: ${({ theme }) => theme.font.size.lg};
  color: ${({ theme }) => theme.color.font.secondary};
`;
