import styled from "styled-components";

export const StyledReason = styled.div`
  width: calc(25% - (25px * 3 / 4));
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    width: calc(33% - (25px * 2 / 3));
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`;

export const HeadlineWrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: flex-start;
`;
export const Number = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
  font-family: bold;
  font-size: ${({ theme }) => theme.font.size.lg};
  padding: 3px 3px 3px 15px;
  background-color: ${({ theme }) => theme.color.background.bayka};
`;
export const Headline = styled.div`
  color: ${({ theme }) => theme.color.font.primary};
  font-family: "bold";
  text-align: left;
`;

export const TextToggle = styled.div`
  color: ${({ theme }) => theme.color.font.primary};
  font-size: ${({ theme }) => theme.font.size.sm};
  text-align: center;
  cursor: pointer;
  display: inline;
  &:hover {
    text-decoration: underline;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: none;
  }
`;

export const Text = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  color: ${({ theme }) => theme.color.font.primary};
  margin-top: 15px;
  ${({ show }) =>
    !show &&
    `
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  `}
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    white-space: normal;
  }
`;
