import styled from "styled-components";

export const StyledLocation = styled.div`
  margin: 50px 0;
`;

export const Headline = styled.div`
  font-family: "bold";
`;
export const Text = styled.div`
  margin-top: 15px;
`;

export const Enumeration = styled.ul`
  margin-top: 15px;
  margin-left: 20px;
`;
export const EnumerationItem = styled.li``;

export const Address = styled.div`
  margin-top: 25px;
`;

export const AddressItem = styled.div`
  ${({ bold }) => bold && 'font-family: "bold"'};
`;

export const Map = styled.img`
  max-height: 400px;
  max-width: 100%;
  margin-top: 15px;
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    width: 100%;
    max-width: none;
    max-height: none;
  }
`;
