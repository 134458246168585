import { StyledJobList } from "./JobList.Styled";
import Job from "./Job/Job";

const JobList = ({ jobs }) => {
  return (
    <StyledJobList>
      {jobs.map((job) => (
        <Job job={job} key={job.id} />
      ))}
    </StyledJobList>
  );
};

export default JobList;
