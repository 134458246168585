//
// ONLY EDIT THIS FILE IN /frontend/src/
//

const translation = {
  header: {
    pages: [
      {
        name: "Startseite",
        href: "/",
      },
      {
        name: "Über uns",
        href: "/ueber-uns",
      },
      { name: "Kontakt", href: "/kontakt" },
      {
        name: "Beschwerdesystem",
        href: "/beschwerdesystem",
      },
    ],
  },

  contact: {
    title: ["Kontakt."],
    headline: "KONTAKT",
    form: {
      label: "Melde dich bei uns",
      cta: "Senden",
      success:
        "Ihre Nachricht wurde erfolgreich verschickt. Wir melden uns schnellstmöglich bei Ihnen.",
      error:
        "Ihre Nachricht konnte nicht verschickt werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.",
    },
    companies: [
      {
        name: "ETL Protax GmbH",
        nameType: "Steuerberatungsgesellschaft",
        street: "Im Bruch 18",
        city: "63897 Miltenberg",
        mail: "info@etl-protax.de",
        phone: "+49 (0) 9371 / 99800",
      },
      {
        name: "ETL Müller · Schmidt · Konrad & Kollegen GmbH",
        nameType: "Steuerberatungsgesellschaft",
        street: "Magnolienweg 14",
        city: "63741 Aschaffenburg",
        mail: "info@etl-protax.de",
        phone: "+49 (0) 9371 / 99800",
      },
      {
        name: "ETL Kontreu GmbH",
        nameType: "Steuerberatungsgesellschaft",
        street: "Ohmbachsgasse 6",
        city: "63739 Aschaffenburg",
        mail: "info@etl-protax.de",
        phone: "+49 (0) 9371 / 99800",
      },
    ],
  },

  career: {
    title: ["TEAMGEIST.", "LEIDENSCHAFT.", "QUALITÄT."],
    by: "Wir sind die Bayka Berlin!",
    cta: "Weitere Infos >",
    openPositions: {
      headline: "BEWIRB DICH JETZT",
      jobs: {
        publishedOn: "veröffentlicht: ",
        cta: "ZUR STELLENAUSSCHREIBUNG",
      },
    },
    history: {
      prefix:
        "Die Bayka Berlin GmbH (vormals Kerpen Starkstromkabel Berlin) hat sich auf die Herstellung von Starkstromkabeln spezialisiert. Die Kompetenz qualifizierter Mitarbeiter:in sichern eine maximale Produktivität auf höchstem Qualitätsniveau.",
      link: {
        text: "Mehr hier...",
        href: "/ueber-uns",
      },
    },
    boxes: [
      {
        headline: "Tragfähige Innovationen brauchen ein stabiles Fundament.",
        text: "Auch in turbulenten Zeiten hat uns der Zusammenhalt innerhalb der Bayka Familie zum Erfolg getragen. Werte wie Ehrlichkeit, Nachhaltigkeit, Zuverlässigkeit und Respekt sind tief in unserer Unternehmens-DNA verankert. Faire Arbeitsbedingungen, Gleichbehandlung, kurze Wege und Offenheit von Seiten der Geschäftsleitung tragen zu einem wertschätzenden Umgang untereinander bei.",
      },
      {
        headline: "Gekommen, um zu bleiben",
        text: [
          "Sicherheit im Job und gegenseitiges Vertrauen schaffen eine Win-win-Situation für alle, denn sowohl Mitarbeitende als auch Geschäftsführung können langfristig planen. Als Teil der inhabergeführten, finanziell solide aufgestellten Firmen-Gruppe haben wir uns den Charakter eines Familienunternehmens erhalten. Wir fertigen ausschließlich „made in Germany“ und sind ein zuverlässiger und innovativer Partner für unsere Kunden in aller Welt. Unser Team ist eine bunte Mischung aus jung und erfahren und freut sich auf Ihre Bewerbung.",
        ],
      },
    ],
    reasons: {
      cta: "Zu unseren Stellenangeboten",
      headline: "11 GRÜNDE TEAMMITGLIED ZU WERDEN",
      pointTextToggle: {
        open: "mehr",
        close: "weniger",
      },
      points: [
        {
          headline: "Gesundheitsvorsorge",
          text: "Arbeitsmedizinische Vorsorge, Unfallverhütung, Arbeitsschutz haben den höchsten Stellenwert im Unternehmen.",
        },
        {
          headline: "Betriebliches Vorschlagswesen",
          text: "Wer kennt die Potentiale besser als die eigene Belegschaft? Deshalb gibt es ein Prämiensystem für Verbesserungsvorschläge.",
        },
        {
          headline: "Urlaub + Urlaubsgeld",
          text: "Wichtig ist, der Urlaub (28-30 Tage) gehört dem Mitarbeiter* – ständige Erreichbarkeit muss nicht sein.",
        },
        {
          headline: "Weihnachtsgeld",
          text: "Jedem Mitarbeiter fließt eine betriebliche Sonderzahlung zu.",
        },
        {
          headline: "Sonderurlaub",
          text: "Bei besonderen Ereignissen wie Hochzeit, Geburt oder Todesfall in der Familie, gewähren wir Sonderurlaub.",
        },
        {
          headline: "Arbeitskleidung",
          text: "Wir stellen unseren Mitarbeitern im gewerblichen Bereich über einen Mietwäsche-Service die Arbeitskleidung kostenfrei zur Verfügung.",
        },
        {
          headline: "Betriebsrat",
          text: "Wir pflegen mit unserem 3-köpfigen Betriebsrat ein offenes und ehrliches Miteinander.",
        },
        {
          headline: "Weihnachtsfeier",
          text: "Wir fördern mit Geldzuschüssen Treffen der Mitarbeiter abseits des Arbeitsalltags.",
        },
        {
          headline: "Weiterbildung",
          text: "Wir legen großen Wert darauf, dass sich unsere Mitarbeiter weiterbilden, ob bei internen Schulungsmaßnahmen oder externen Seminaren und Workshops.",
        },
        {
          headline: "Fahrradleasing",
          text: "Gut für Umwelt und Gesundheit. Das sogenannte „Dienstradleasing“ (Fahrradleasing) funktioniert über die Entgeltumwandlung. Ab 7. Monat Zugehörigkeit.",
        },
        {
          headline: "Corporate Benefits",
          text: "Rabatte und tolle Angebote ein umfangreiches Programm und dauerhafte Vergünstigungen.",
        },
      ],
      genderHint:
        "*Gender-Hinweis: Aus Gründen der besseren Lesbarkeit wird auf die gleichzeitige Verwendung der Sprachformen männlich, weiblich und divers (m/w/d) verzichtet. Sämtliche Personenbezeichnungen gelten gleichermaßen für alle Geschlechter.",
    },
    banner: {
      line1: "“Wo die Bedürfnisse der Welt mit deinen Talenten",
      line2: "zusammentreffen, dort liegt deine Berufung.” Aristoteles",
    },
    people: {
      headline: "Unsere Geschäftsleitung",
      subHeadline:
        "Gemeinsam mit Ihrem Team, verkörpern die drei Geschäftsführer die Werte und Ziele der ETL Protax.",
      list: [
        {
          firstname: "Matthias",
          lastname: "Heine",
          title: "Werkleiter",
          text: null,
          mail: "heine.m@bayka-berlin.de",
          isGF: true,
        },
      ],
    },
  },

  aboutUs: {
    title: ["Wir sind die Bayka Berlin!"],
    text: [
      "Die Produktionsmöglichkeiten reichen von der Herstellung der erforderlichen Aluminiumleiter bis zum fertigen Kabel in Standard- oder auf Kundenwunsch.",
      "Die Zugehörigkeit zu einer führenden europäischen Kabelgruppe ermöglicht dabei den Zugriff auf verschiedene vorgelagerte Wertschöpfungen bspw. Drahtzug oder Kunststoffproduktion. Neben standardisierten Kabeln und Kabeln nach Norm kann die Bayka Berlin auch kundenspezifische Lösungen realisieren. Seit vielen Jahren vertrauen namhafte Kunden aus dem Bereich der Energieversorgung, dem Anlagenbau und der Industrie auf die Kabel aus der Bayka Berlin.",
      "Neben der Produktion - am Standort Bayka Berlin - gehören das Prüffeld, die Instandhaltung und die Logistik sowie die Administration zu den Schwerpunkten im Werk.",
    ],
    location: {
      headline: "Standort",
      navigationTexts: [
        "Bayka Berlin produziert Energiekabel für eine Vielzahl an Kunden in Deutschland und Europa. BayEnergy® Kabel - vor allem Kabel mit Aluminiumleiter - werden an diesem Standort produziert.",
        "Bayka Berlin ist verkehrsgünstig im Nordwesten Berlins (Stadtteil Borsigwalde) gelegen, was nicht nur materiallogistische Vorteile bringt, sondern den Angestellten gute Erreichbarkeit ermöglicht:",
      ],
      enumeration: [
        "U-Bahn „Holzhauser Straße“ nur 800 m",
        "S-Bahn „Eichborndamm“ nur 950 m",
        "Autobahn 111 – Ausfahrt „Holzhauser Straße“ nur 550 m",
      ],
      infoText:
        "Der Standort ist ein traditioneller Standort für Kabelfertigung (früher Kerpenwerke) und bereits seit Anfang der 2000er Jahre Teil der Wilms-Gruppe und Tochterunternehmen der Bayka (Bayerische Kabelwerke AG in Roth).",
    },
  },

  imprint: {
    headline: "Impressum",
  },

  dataProtection: {
    headline: "Datenschutz",
  },

  footer: {
    legal: [
      { name: "Impressum", href: "https://www.bayka.de/impressum/" },
      {
        name: "Datenschutz",
        href: "https://www.bayka.de/datenschutzerklaerung/",
      },
      {
        name: "Haftungsausschluss",
        href: "https://www.bayka.de/haftungsausschluss/",
      },
      {
        name: "AVB",
        href: "https://www.bayka.de/allgemeine-verkaufsbedingungen-avb-fuer-lieferungen-und-leistungen/",
      },
    ],
  },

  genericInfo: {
    name: "BAYERISCHE KABELWERKE AG",
    street: "Otto-Schrimpff-Straße 2",
    city: "D – 91154 Roth",
    mailBox: "Postfach 1153",
    mailBoxCity: "D – 91152 Roth",
    mail: "kabel@bayka.de",
    phone: "+49 (0) 9171/806-111",
    fax: "+49 (0) 9171/806-222",
    location: {
      company: "Bayka Berlin GmbH",
      street: "Soltauer Straße 8",
      city: "13509 Berlin",
    },
  },

  generic: {
    salutation: "Anrede",
    firstname: "Vorname",
    lastname: "Nachname",
    name: "Name",
    street: "Straße",
    postcode: "Postleitzahl",
    city: "Stadt",
    phone: "Tel.: ",
    fax: "Fax: ",
    mail: "E-Mail: ",
    password: "Passwort",
    message: "Nachricht",
  },

  UI: {
    dropdown: {
      emptySelection: "Bitte wählen",
    },
  },
};

module.exports = {
  translation,
  langKey: "DE",
};
