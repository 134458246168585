import { Container } from "@think-internet/ui-components";
import { useSelector } from "react-redux";
import props from "../../../redux/props";
import { StyledOpenPositions, Headline } from "./OpenPositions.Styled";
import { useEffect, useState } from "react";
import { getJobs } from "../../../interface/b-ite";
import JobList from "./JobList/JobList";

const OpenPositions = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  const [jobs, setJobs] = useState(null);

  useEffect(() => {
    const listJobs = async () => {
      const jobs = await getJobs();
      if (Array.isArray(jobs)) {
        setJobs(jobs);
      }
    };
    if (!jobs) {
      listJobs();
    }
  }, [jobs]);

  if (!jobs) return null;
  return (
    <StyledOpenPositions>
      <Headline id="positions">
        {translation.career.openPositions.headline}
      </Headline>
      <Container>
        <JobList jobs={jobs} />
      </Container>
    </StyledOpenPositions>
  );
};

export default OpenPositions;
