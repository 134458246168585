import { useSelector } from "react-redux";
import {
  StyledFooter,
  Column,
  CustomLink,
  CustomText,
  Flex,
  Socials,
  Social,
  SocialIcon,
} from "./Footer.Styled";
import { Container } from "@think-internet/ui-components";
import props from "../../../redux/props";
import linkedin from "../../../assets/image/footer/linkedin.webp";
import facebook from "../../../assets/image/footer/facebook.webp";
import instagram from "../../../assets/image/footer/instagram.webp";
import tiktok from "../../../assets/image/footer/tiktok.webp";
import youtube from "../../../assets/image/footer/youtube.webp";
import { getUUID } from "../../../utility";

const Footer = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  const socials = [
    {
      img: linkedin,
      href: "https://www.linkedin.com/company/bayerische-kabelwerke-ag/?originalSubdomain=de",
    },
    { img: facebook, href: "https://www.facebook.com/BayerischeKabelwerkeAG/" },
    { img: instagram, href: "https://www.instagram.com/bayka_roth/" },
    { img: tiktok, href: "https://www.tiktok.com/@bayka_roth" },
    {
      img: youtube,
      href: "https://www.youtube.com/channel/UCwCTVwLEvzNHJO6dtVR8Wlg",
    },
  ];

  return (
    <StyledFooter>
      <Container>
        <Flex>
          <Column>
            {translation.footer.legal.map((i) => (
              <CustomLink
                key={getUUID()}
                block
                newTab
                href={i.href}
                text={i.name}
              />
            ))}
          </Column>
          <Column>
            <CustomText>{translation.genericInfo.name}</CustomText>
            <CustomText>{translation.genericInfo.street}</CustomText>
            <CustomText>{translation.genericInfo.city}</CustomText>
            <CustomText margin>{translation.genericInfo.mailBox}</CustomText>
            <CustomText>{translation.genericInfo.mailBoxCity}</CustomText>
            <CustomText margin>
              {translation.generic.phone}
              {translation.genericInfo.phone}
            </CustomText>
            <CustomText>
              {translation.generic.fax}
              {translation.genericInfo.fax}
            </CustomText>
            <CustomText margin>
              {translation.generic.mail}
              <CustomLink
                href={`mailto:${translation.genericInfo.mail}`}
                text={translation.genericInfo.mail}
                newTab
              />
            </CustomText>
          </Column>
          <Column>
            <CustomText bold>FOLGE UNS</CustomText>
            <Socials>
              {socials.map((s) => (
                <Social key={getUUID()} href={s.href} target="_blank">
                  <SocialIcon src={s.img} />
                </Social>
              ))}
            </Socials>
            <CustomText>
              Copyright © {new Date().getFullYear()} BAYERISCHE KABELWERKE AG.
              Alle Rechte vorbehalten.
            </CustomText>
          </Column>
        </Flex>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
