import { call, put, select } from 'redux-saga/effects'
import { list as crudList, get as crudGet, update as crudUpdate, create as crudCreate, remove as crudRemove } from '../../../interface/crud'
import * as operation from '../../operation'
import props from '../../props'

export function* list(action) {
  try {
    const { query, featureName, replace } = action.payload
    const state = yield select()
    const response = yield call(crudList, state[props.TOKEN], featureName, query)
    yield put({ type: featureName, operation: operation.LIST, payload: response, replace })
  } catch (error) {
    console.log(error)
    yield false
  }
}

export function* get(action) {
  try {
    const { query, featureName, replace } = action.payload
    const state = yield select()
    const response = yield call(crudGet, state[props.TOKEN], featureName, query)
    yield put({ type: featureName, operation: operation.GET, payload: response, replace })
  } catch (error) {
    console.log(error)
    yield false
  }
}

export function* create(action) {
  try {
    const { query, featureName, replace } = action.payload
    const state = yield select()
    const response = yield call(crudCreate, state[props.TOKEN], featureName, query)
    yield put({ type: featureName, operation: operation.CREATE, payload: response, replace })
  } catch (error) {
    console.log(error)
    yield false
  }
}

export function* update(action) {
  try {
    const { query, featureName, replace } = action.payload
    const state = yield select()
    const response = yield call(crudUpdate, state[props.TOKEN], featureName, query)
    yield put({ type: featureName, operation: operation.UPDATE, payload: response, replace })
  } catch (error) {
    console.log(error)
    yield false
  }
}

export function* upsert(action) {
  try {
    const { query, featureName, replace } = action.payload
    const state = yield select()
    let response = false
    if (query.uuid) {
      response = yield call(crudUpdate, state[props.TOKEN], featureName, query)
    } else {
      response = yield call(crudCreate, state[props.TOKEN], featureName, query)
    }

    if (response) yield put({ type: featureName, operation: operation.UPDATE, payload: response, replace })
  } catch (error) {
    console.log(error)
    yield false
  }
}

export function* multiUpsert(action) {
  try {
    const { query, featureName } = action.payload
    if (Array.isArray(query)) {
      yield put({ type: featureName, operation: operation.UPDATE, payload: [], replace: true })
      for (const item of query) {
        yield call(upsert, { payload: { query: item, featureName, replace: false } })
      }
    }
  } catch (error) {
    console.log(error)
    yield false
  }
}

export function* remove(action) {
  try {
    const { query, featureName } = action.payload
    const state = yield select()
    const response = yield call(crudRemove, state[props.TOKEN], featureName, query)
    yield put({ type: featureName, operation: operation.DELETE, payload: response })
  } catch (error) {
    console.log(error)
    yield false
  }
}
