import { Container } from "@think-internet/ui-components";
import styled from "styled-components";

export const HistoryRelativeWrapper = styled(Container)`
  position: relative;
`;

export const StyledHistory = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  margin-top: 50px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
    top: 0px;
    position: relative;
    width: 100%;
  }
`;
export const Box = styled.div`
  background-color: ${({ theme }) => theme.color.background.fifth};
  padding: 25px 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`;

export const Text = styled.div`
  font-size: ${({ theme }) => theme.font.size.md};
`;

export const Link = styled.a`
  font-size: ${({ theme }) => theme.font.size.md};
  color: ${({ theme }) => theme.color.font.bayka};
  padding-left: 5px;
`;
