import { useSelector } from "react-redux";
import {
  StyledLocation,
  Headline,
  Text,
  Enumeration,
  EnumerationItem,
  Address,
  AddressItem,
  Map,
} from "./Location.Styled";
import { Container } from "@think-internet/ui-components";
import props from "../../../redux/props";
import { getUUID } from "../../../utility";
import map from "../../../assets/image/about-us/location/map.png";

const Location = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);

  return (
    <StyledLocation>
      <Container>
        <Headline>{translation.aboutUs.location.headline}</Headline>
        {translation.aboutUs.location.navigationTexts.map((t) => (
          <Text key={getUUID()}>{t}</Text>
        ))}
        <Enumeration>
          {translation.aboutUs.location.enumeration.map((e) => (
            <EnumerationItem key={getUUID()}>{e}</EnumerationItem>
          ))}
        </Enumeration>
        <Text>{translation.aboutUs.location.infoText}</Text>
        <Address>
          <AddressItem bold>
            {translation.genericInfo.location.company}
          </AddressItem>
          <AddressItem>{translation.genericInfo.location.street}</AddressItem>
          <AddressItem>{translation.genericInfo.location.city}</AddressItem>
        </Address>
        <Map src={map} />
      </Container>
    </StyledLocation>
  );
};

export default Location;
