import { getUUID } from "../../../utility";
import {
  StyledHero,
  HeroImage,
  HeroContentContainer,
  HeroContentWrapper,
  By,
  Title,
} from "./Hero.Styled";

const Hero = ({ title, by, bg, center = false, top = false }) => {
  return (
    <StyledHero>
      <HeroImage src={bg} top={top} />
      <HeroContentContainer center={center}>
        <HeroContentWrapper>
          {title.map((t) => (
            <Title key={getUUID()}>{t}</Title>
          ))}
          <By>{by}</By>
        </HeroContentWrapper>
      </HeroContentContainer>
    </StyledHero>
  );
};

export default Hero;
