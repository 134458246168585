import { StyledLayout, CoreContent } from "./Layout.Styled";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { useEffect } from "react";
import usePrevious from "./usePrevious";

const Layout = ({
  children,
  className,
  hideHeader = false,
  hideFooter = false,
}) => {
  const url = window.location.href;
  const prevURL = usePrevious(url);

  useEffect(() => {
    const scrollToTop = () => {
      document.body.scrollTop = 0;
    };

    if (url !== prevURL) scrollToTop();
  }, [url, prevURL]);

  return (
    <StyledLayout className={className}>
      {!hideHeader && <Header />}
      <CoreContent>{children}</CoreContent>
      {!hideFooter && <Footer />}
    </StyledLayout>
  );
};

export default Layout;
