import styled, { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`

* {
  padding: 0px;
  margin: 0px;
  font-family: "regular", sans-serif;
  font-size: ${({ theme }) => theme.font.size.md};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.sm};
  }
}

html,
body {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
}

.Toastify {
  * {
  font-size: ${({ theme }) => theme.font.size.sm};
  }
}

`;

export const HiddenOnMobile = styled.div`
  display: block;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: none;
  }
`;

export const HiddenOnDesktop = styled.div`
  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: block;
  }
`;

export default GlobalStyles;
