import { call as sagaCall, put, select } from 'redux-saga/effects'
import call from '../../../interface/functional'
import * as operation from '../../operation'
import props from '../../props'

export default function* functionalCall(action) {
  try {
    const { route, body, files, replace } = action.payload
    const state = yield select()
    const response = yield sagaCall(call, state[props.TOKEN], route, body, files)
    yield put({ type: route, operation: operation.FUNCTIONAL_CALL, payload: response, replace })
  } catch (error) {
    console.log(error)
    yield false
  }
}
