import styled from "styled-components";

export const StyledJob = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: thin solid ${({ theme }) => theme.color.border.bayka};
  padding: 10px 0;
  &:last-child {
    border-bottom: none;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    flex-direction: column;
    gap: 15px;
    align-items: baseline;
  }
`;

export const Info = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
`;
export const ModifiedDate = styled.div`
  font-family: "thin";
`;
export const Name = styled.div`
  font-size: 20px;
`;
export const CTA = styled.a`
  font-size: ${({ theme }) => theme.font.size.sm};
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.color.background.bayka};
  color: ${({ theme }) => theme.color.font.secondary};
  padding: 7px;
  border: 3px solid ${({ theme }) => theme.color.border.bayka};
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.color.font.bayka};
    background-color: ${({ theme }) => theme.color.background.primary};
    cursor: pointer;
  }
`;
