import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import { watcherSaga } from './saga/rootSaga'
import reducer from './reducer'

const sagaMiddleware = createSagaMiddleware()

const middleware = [sagaMiddleware]

const store = configureStore({ reducer, middleware })

sagaMiddleware.run(watcherSaga)

export default store
