import { useSelector } from "react-redux";
import { getPrettyDate } from "../../../../../utility";
import { StyledJob, Info, ModifiedDate, Name, CTA } from "./Job.Styled";
import props from "../../../../../redux/props";

const Job = ({ job }) => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  return (
    <StyledJob>
      <Info>
        <ModifiedDate>
          {translation.career.openPositions.jobs.publishedOn}
          {getPrettyDate(new Date(job.modifiedOn))}
        </ModifiedDate>
        <Name>{job.title}</Name>
      </Info>
      <CTA href={job.url} target="_blank">
        {translation.career.openPositions.jobs.cta}
      </CTA>
    </StyledJob>
  );
};

export default Job;
