import { takeEvery } from 'redux-saga/effects'
import { list, get, create, update, remove, upsert, multiUpsert } from './handlers/crud'
import functionalCall from './handlers/functional'
import * as operation from '../operation'

export function* watcherSaga() {
  yield takeEvery(operation.LIST, list)
  yield takeEvery(operation.GET, get)
  yield takeEvery(operation.CREATE, create)
  yield takeEvery(operation.UPDATE, update)
  yield takeEvery(operation.DELETE, remove)
  yield takeEvery(operation.UPSERT, upsert)
  yield takeEvery(operation.MULTI_UPSERT, multiUpsert)
  yield takeEvery(operation.FUNCTIONAL_CALL, functionalCall)
}
