import logo from "../../../assets/image/logo.png";
import darkLogo from "../../../assets/image/logo.png";
import { StyledLogo } from "./Logo.Styled";

const Logo = ({ height, dark = false, onClick = null }) => {
  return (
    <StyledLogo
      onClick={onClick ? onClick : null}
      height={height}
      src={dark ? darkLogo : logo}
    />
  );
};

export default Logo;
