import styled from "styled-components";
import Layout from "../Layout/Layout";

const IFrame = styled.iframe`
  border: none;
  width: 100%;
  height: calc(100vh + 85px);
  margin-top: -90px;
`;

const ComplaintSystem = () => {
  return (
    <Layout authRequired={false} hideFooter={true}>
      <IFrame src="https://www.bayka.de/unternehmen/beschwerdesystem/" />
    </Layout>
  );
};
export default ComplaintSystem;
