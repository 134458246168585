import styled from "styled-components";
import { Container, Link } from "@think-internet/ui-components";

export const StyledHeader = styled.div`
  position: absolute;
  width: 100vw;
  height: ${({ theme }) => theme.header.height};
  z-index: 999;
  top: 0;
  background-color: ${({ theme }) => theme.header.background};
  box-shadow: ${({ theme }) => theme.shadow};
`;

export const Wrapper = styled(Container)`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;
`;

export const Toggle = styled.img`
  cursor: pointer;
  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: block;
    height: 20px;
  }
`;

export const CustomLink = styled(Link)`
  color: ${({ theme }) => theme.header.font};
  display: flex;
  align-self: center;
  cursor: pointer;
  white-space: nowrap;
  width: 100%;
  &:hover,
  &.active {
    color: ${({ theme }) => theme.color.font.bayka};
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: block;
    width: 100%;
    margin-bottom: 15px;
  }
`;

export const Pages = styled.div`
  display: flex;
  gap: 30px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: none;
  }
`;

export const MobileNavigation = styled.div`
  padding: 15px;
  background-color: ${({ theme }) => theme.header.background};
  position: absolute;
  width: 100vw;
  height: calc(100vh - ${({ theme }) => theme.header.height});
  margin-top: ${({ theme }) => theme.header.height};
  top: 0px;
  left: 0px;
`;

export const LogoWrapper = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;
