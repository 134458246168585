import { useSelector } from "react-redux";
import props from "../../../redux/props";
import {
  BoxesRelativeWrapper,
  StyledBoxes,
  Box,
  Headline,
  Text,
} from "./Boxes.Styled";
import { getUUID } from "../../../utility";

const Boxes = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);

  return (
    <BoxesRelativeWrapper>
      <StyledBoxes>
        {translation.career.boxes.map(({ headline, text }) => (
          <Box key={getUUID()}>
            <Headline>{headline}</Headline>
            <Text>{text}</Text>
          </Box>
        ))}
      </StyledBoxes>
    </BoxesRelativeWrapper>
  );
};

export default Boxes;
