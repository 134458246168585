import { StyleSeparator, Image } from "./Separator.Styled";
import { Container } from "@think-internet/ui-components";
import image from "../../../assets/image/career/separator/image.png";

const Separator = () => {
  return (
    <Container>
      <StyleSeparator>
        <Image src={image} />
      </StyleSeparator>
    </Container>
  );
};

export default Separator;
