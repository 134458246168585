import styled from "styled-components";

export const StyledImages = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 50px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    gap: 10px;
  }
`;

export const Image = styled.img`
  width: calc(33% - (20px * 2 / 3));
  aspect-ratio: 16/12;
  object-fit: cover;
  height: fit-content;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: calc(50% - (10px / 2));
  }
`;
