import { useSelector } from "react-redux";
import props from "../../../redux/props";
import {
  StyledReasons,
  ContentWrapper,
  CTA,
  Headline,
  PointWrapper,
  GenderHint,
} from "./Reasons.Styled";
import { getUUID } from "../../../utility";
import { Container } from "@think-internet/ui-components";
import Reason from "./Reason/Reason";
// import bulletLine from "../../../assets/image/career/reasons/bullet-line.webp";

const Reasons = () => {
  const t = useSelector((s) => s[props.TRANSLATION]);
  return (
    <StyledReasons>
      <Container>
        <ContentWrapper>
          <CTA href="#positions">{t.career.reasons.cta}</CTA>
          {/* <ButtletLine src={bulletLine} /> */}
          <Headline>{t.career.reasons.headline}</Headline>
          <PointWrapper>
            {t.career.reasons.points.map((data, i) => (
              <Reason key={getUUID()} data={data} i={i} />
            ))}
          </PointWrapper>
        </ContentWrapper>
        <GenderHint>{t.career.reasons.genderHint}</GenderHint>
      </Container>
    </StyledReasons>
  );
};

export default Reasons;
