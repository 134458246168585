import { useSelector } from "react-redux";
import props from "../../../redux/props";
import { StyledBanner, Glass, TextWrapper, Text } from "./Banner.Styled";
import bgImage from "../../../assets/image/career/banner/bg.webp";

const Banner = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  return (
    <StyledBanner bg={bgImage}>
      <Glass>
        <TextWrapper>
          <Text>{translation.career.banner.line1}</Text>
          <Text>{translation.career.banner.line2}</Text>
        </TextWrapper>
      </Glass>
    </StyledBanner>
  );
};

export default Banner;
