import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GlobalStyle from "./Global.Styled";
import { ThemeProvider } from "styled-components";
import theme from "./theme";
import "./global.scss";
import Career from "./components/Career/Career";
import Contact from "./components/Contact/Contact";
import AboutUs from "./components/AboutUs/AboutUs";
import ComplaintSystem from "./components/ComplaintSystem/ComplaintSystem";

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Router>
          <Routes>
            <Route index element={<Career />} />
            <Route path="ueber-uns" element={<AboutUs />} />
            <Route path="kontakt" element={<Contact />} />
            <Route path="beschwerdesystem" element={<ComplaintSystem />} />
            <Route path={"*"} element={<Navigate replace to="/" />} />
          </Routes>
        </Router>
        <ToastContainer />
      </ThemeProvider>
    </div>
  );
}

export default App;
