import { Container } from "@think-internet/ui-components";
import { useSelector } from "react-redux";
import props from "../../../redux/props";
import {
  StyledPeople,
  List,
  Person,
  Image,
  Name,
  Title,
  Text,
  Value,
  ValueLink,
  MailIcon,
} from "./People.Styled";
import { getUUID } from "../../../utility";
import person from "../../../assets/image/career/person.webp";

const People = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  const images = [person];
  return (
    <StyledPeople>
      <Container>
        <List>
          {translation.career.people.list.map((p, i) => (
            <Person key={getUUID()}>
              <Image src={images[i]} />
              <Name>{`${p.firstname} ${p.lastname}`}</Name>
              <Title>{p.title}</Title>
              {p.text && <Text>{p.text}</Text>}
              <Value>
                <MailIcon>@</MailIcon>
                <ValueLink href={`mailto:${p.mail}`}>{p.mail}</ValueLink>
              </Value>
            </Person>
          ))}
        </List>
      </Container>
    </StyledPeople>
  );
};

export default People;
